import React from 'react'
import styled from '@emotion/styled'

const SVG = styled.svg`
  padding: 0;
  opacity: 40%;
`

const BehanceIcon = props => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 226.999 106"
      {...props}
    >
      <path
        id="Fill_9"
        data-name="Fill 9"
        d="M225.845,106h-.03a.881.881,0,0,1-.1,0L118.4,96.833a.25.25,0,0,1-.1-.037.309.309,0,0,0-.071-.032c-.03-.007-.06-.012-.089-.018a.658.658,0,0,1-.214-.064.236.236,0,0,1-.048-.043.341.341,0,0,0-.033-.032.079.079,0,0,0-.03-.011.08.08,0,0,1-.03-.011L.421,2.005A1.128,1.128,0,0,1,1.6.1L226.125,103.776a.183.183,0,0,1,.079.046.229.229,0,0,0,.06.04,1.206,1.206,0,0,1,.285.177,1.063,1.063,0,0,1,.364.587.028.028,0,0,0,0,.012.029.029,0,0,1,0,.012.393.393,0,0,0,.038.092.282.282,0,0,1,.043.13A1.111,1.111,0,0,1,225.845,106ZM125.4,86.082l-5.03,8.653,85.269,7.284ZM16.034,9.251h0l109.24,74.507,91.446,18.158L16.034,9.251Zm2.324,4.314h0l99.823,80.439,5.147-8.845L18.357,13.565Z"
        transform="translate(-0.001 0)"
        fill="#6d6e71"
      />
    </SVG>
  )
}

export default BehanceIcon
