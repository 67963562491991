import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

const Header = styled.header`
  background: ${props => props.theme.colors.primary};
  width: 100%;

  .home-lg {
    display: none;
  }

  nav {
    background-color: #fff;
    padding: 1.063rem 1.25rem;
    opacity: 0.95;
  }

  .navbar-brand {
    padding: 0;
  }

  .navbar-toggler {
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    height: 20px;
    width: 20px;

    div {
      width: 100%;
      height: 2px;
      background-color: #000;
    }
  }

  img {
    max-width: 79px;
  }

  li {
    margin-bottom: 38px;
    text-align: center;
    a {
      color: ${props => props.theme.colors.text};
    }
  }
  li:first-of-type {
    margin-top: 0.875rem;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    nav {
      height: 80px;
      margin: 0 auto;
      padding: 0 1.5em;
      opacity: 1;
    }
    .navbar-brand {
      display: none;
    }
    .navbar-toggler {
      display: none;
    }

    .navbar-collapse {
      height: 100%;
    }

    .navbar-nav {
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: end;
      width: 100%;
      margin: 0 auto;
      max-width: ${props => props.theme.sizes.maxWidth};
    }

    li {
      padding: 0;
      margin: 0 0 0 100px;
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
    }

    li:first-of-type {
      margin-top: 0;
    }

    .home-lg {
      min-width: 101px;
      display: flex;
      margin-left: 0;
      margin-right: auto;
    }

    .item-line {
      height: 6px;
      background-color: ${props => props.theme.colors.primary};
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all ease 0.5s;
      width: 100%;
    }

    a {
      text-decoration: none;
      color: #000;
      font-weight: 500;
      font-size: 0.875rem;
      padding: 0;
      transition: color ease 0.5s;
      &:hover {
        color: ${props => props.theme.colors.secondary};
        .item-line {
          background-color: ${props => props.theme.colors.secondary};
        }
      }
    }

    [aria-current]:not([aria-current='false']) {
      color: ${props => props.theme.colors.secondary};
      .item-line {
        background-color: ${props => props.theme.colors.secondary};
      }
    }

    .home-lg {
      a {
        border: 0;
      }
      img {
        max-width: 101px;
      }
    }
  }
`

const Menu = () => {
  const { menuLinks } = useSiteMetadata()

  return (
    <Header>
      <Navbar expand="md" fixed="top">
        <Navbar.Brand href="/">
          <img src="/images/save@2x.png" alt="SAVE" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <div></div>
          <div></div>
          <div></div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {menuLinks.map((link, idx) => {
              if (link.slug !== '/') {
                return (
                  <li key={link.name}>
                    <Link to={link.slug}>
                      {link.name}
                      <span className="item-line"></span>
                    </Link>
                  </li>
                )
              }
              return (
                <li key={link.name} className="home-lg">
                  <Link to={link.slug}>
                    <img src="/images/save@2x.png" alt="SAVE" />
                  </Link>
                </li>
              )
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Header>
  )
}

export default Menu
