import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: calc(${props => props.theme.sizes.maxWidth} + (2 * 1.5em));
  padding: ${props => props.padding || '6.154em 1.5em 2em'};
  flex-grow: 1;

  @media screen and (min-width: 62em) {
    padding: 5em 1.5em 2em;
  }
`

const Container = props => {
  return <Wrapper padding={props.padding}>{props.children}</Wrapper>
}

export default Container
