import React from 'react'
import styled from '@emotion/styled'

// @assets
import BirdIcon from '../icons/BirdIcon'

const Wrapper = styled.footer`
  background-color: ${props => props.theme.colors.background};
  display: flex;
  color: #4a4a4a;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 0 7.538rem;
  position: relative;
  svg {
    display none;
  }
  @media screen and (min-width: ${props =>
    props.theme.responsive.medium}) and (max-width: ${props =>
  props.theme.responsive.large}) {
    padding: 3rem 0 10.538rem;
    svg {
      position: absolute;
      display: block;
    }

    svg:nth-of-type(1) {
      top: -5px;
      width: 191px;
      left: -49px;
    }
    svg:nth-of-type(2) {
      top: -19px;
      width: 227px;
      left: 500px;
      transform: scale(0.6) rotate(20deg);
    }

    svg:nth-of-type(3) {
      top: 100px;
      transform: scale(1.5) rotate(-12deg);
      width: 171px;
      left: 320px;
    }

    svg:nth-of-type(4) {
      top: 79px;
      width: 171px;
      right: -22px;
      transform: rotate(-12deg);
    }
  }

`

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <Wrapper>
      {`SAVE © ${year} All Rights Reserved`}
      <BirdIcon />
      <BirdIcon />
      <BirdIcon />
      <BirdIcon />
    </Wrapper>
  )
}

export default Footer
