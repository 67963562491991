const metaData = {
  home: {
    title: 'Save: Salvando marcas desde 2010',
    description:
      'Save es un espacio dedicado al desarrollo integral de marcas, encontrando su voz a través del equilibrio de su capacidad técnica, estrategia funcional y valor estético.',
  },
  folios: {
    title: 'Los Folios',
    description:
      'Un compendio de 10 años de trabajo en agencias y freelance, alrededor del diseño de identidad corporativa, gestión de marca y experiencia de productos digitales.',
  },
  servicios: {
    title: 'Servicios',
    description:
      'La configuración de una marca está desarrollado bajo el diseño gráfico funcional, la dirección de una comunicación visual efectiva y una presencia digital sólida.',
  },
  premios: {
    title: 'Premios',
    description:
      'Highlights en el camino del diseñador, como prueba de la disciplina y la constancia de un trabajo hecho con pasión.',
  },
  contacto: {
    title: 'Contacto',
    description:
      'Conocer al interlocutor a través de una taza de café, proporciona una facilidad para entender sus necesidades y la historia que quiere contar.',
  },
}

const useStaticMetadata = () => ({
  ...metaData,
})

export default useStaticMetadata
